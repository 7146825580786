import React from 'react';
import { useSelector } from 'react-redux';

import { EditorEventsProvider } from 'wix-rich-content-editor-common/dist/lib/EditorEventsContext';
import { DraftContent } from '@wix/ricos-common';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  groupCreatePostClick,
  groupFeedView,
  groupInviteMembersOptionClicked,
} from '@wix/bi-logger-groups/v2';

import { BIUserEntry } from 'common/bi-logger/types';
import { useController } from 'common/context/controller';
import { EmptyState } from 'common/components/EmptyState';

import {
  selectIsJoinedGroupsQueryLoading,
  selectJoinedGroups,
} from 'store/joined-groups/selectors';
import {
  selectFeedItems,
  selectFeedPagination,
  selectFeedStatuses,
} from 'store/feed/selectors';
import { selectIsSiteAdmin } from 'store/application/selectors';

import { PostEditorModal } from 'Group/Widget/DiscussionPage/GroupFeed/PostEditorModal';
import { FeedItemList } from 'Group/Widget/DiscussionPage/GroupFeed/FeedItemList';
import { CreatePost } from 'Group/Widget/DiscussionPage/GroupFeed/CreatePost';
import { InviteMembersModal } from 'Group/Widget/Header/InviteMembers/InviteMembersModal';

import settingsParams from '../../../../settingsParams';
import { useShadowStyles } from 'common/hooks/useShadowStyles';
import { InviteMembersCard } from '../InviteMembersCard';
import { SelectGroupModal } from './SelectGroupModal';

import { st, classes } from './CentralFeed.st.css';

type CentralFeedProps = {};

export const CentralFeed: React.FC<CentralFeedProps> = () => {
  const { t } = useTranslation();
  const bi = useBi();

  const { centralFeed$, feed$, group$ } = useController();

  const settings = useSettings();

  const [selectedGroupId, setSelectedGroupId] = React.useState('');
  const actionForGroup = React.useRef<() => void>();

  const [dialogs, setDialogs] = React.useState({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const joinedGroups = useSelector(selectJoinedGroups);
  const feedItems = useSelector(selectFeedItems);
  const feedStatuses = useSelector(selectFeedStatuses);
  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const { nextCursor } = useSelector(selectFeedPagination);

  const atLeastOneJoinedGroupWith10PlusMembers = joinedGroups.some(
    (g) => g.membersCount && g.membersCount >= 10,
  );

  const shouldShowInviteMembersCard =
    isSiteAdmin && !atLeastOneJoinedGroupWith10PlusMembers && !nextCursor;

  const noPosts =
    feedItems?.length === 0 &&
    !feedStatuses.fetch.pending &&
    !feedStatuses.fetch.error;

  const shouldShowCreatePost =
    settings.get(settingsParams.showCreatePost) && joinedGroups?.length > 0;

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        container: 'groups',
        roles: isSiteAdmin ? 'admin' : 'member',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }, []);

  if (noPosts) {
    return (
      <EmptyState
        title={t('groups-web.no-posts.title')}
        content={t('groups-web.no-posts.content')}
        className={st(classes.noPosts, {}, classes.feedItem)}
      />
    );
  }

  return (
    <div className={st(classes.root)}>
      {shouldShowCreatePost ? (
        <CreatePost
          onClick={doActionForGroup(openCreatePostDialog)}
          className={classes.createPost}
        />
      ) : null}
      <FeedItemList
        showGroupName={true}
        showSuggestedGroupLabel={true}
        itemClassName={classes.feedItem}
        className={classes.feedItemList}
        onCreatePost={doActionForGroup(openCreatePostDialog)}
        onFetch={handleFetchMore}
      />
      {shouldShowInviteMembersCard ? (
        <InviteMembersCard
          onClick={doActionForGroup(openInviteMembersDialog)}
        />
      ) : null}
      <SelectGroupModal
        isOpen={dialogs.selectGroup}
        onClose={closeDialogs}
        onSelectClick={handleGroupSelect}
      />
      <InviteMembersModal
        isOpen={dialogs.inviteMembers}
        groupId={selectedGroupId}
        onClose={closeDialogs}
      />
      {selectedGroupId ? (
        <EditorEventsProvider>
          <PostEditorModal
            showGroupName={true}
            groupId={selectedGroupId}
            isOpen={dialogs.createPost}
            onSubmit={handlePostCreate}
            onClose={closeDialogs}
            cancelButtonText={t('groups-web.discussion.new-post.back')}
            onCancel={openSelectGroupDialog}
          />
        </EditorEventsProvider>
      ) : null}
    </div>
  );

  function handleFetchMore(cursor?: string) {
    centralFeed$.fetch(cursor);
  }

  function doActionForGroup(cb: () => void) {
    return () => {
      actionForGroup.current = cb;
      if (joinedGroups.length === 1) {
        handleGroupSelect(joinedGroups[0].id!);
      } else {
        openSelectGroupDialog();
      }
    };
  }

  function openCreatePostDialog() {
    bi.report(
      groupCreatePostClick({
        origin: 'new_layout_groups_sidebar',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setDialogs({
      createPost: true,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function openInviteMembersDialog() {
    bi.report(
      groupInviteMembersOptionClicked({
        origin: 'invite_members_sidebar',
        optionType: 'invite',
        userEntry: BIUserEntry.SITE,
      }),
    );
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: true,
    });
  }

  function openSelectGroupDialog() {
    setDialogs({
      createPost: false,
      selectGroup: true,
      inviteMembers: false,
    });
  }

  function closeDialogs() {
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function handlePostCreate(content: DraftContent, topics: string[]) {
    if (selectedGroupId) {
      feed$.create(selectedGroupId, JSON.stringify(content), topics);
    }
  }

  function handleGroupSelect(groupId: string) {
    group$.fetch(groupId);
    setSelectedGroupId(groupId);
    actionForGroup?.current?.();
  }
};

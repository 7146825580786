import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority, Text, TextPriority } from 'wix-ui-tpa';
import cls from 'classnames';

import { BlackAndWhiteButton } from 'common/components/BlackAndWhiteButton';
import { skeleton } from 'common/components/Skeleton';

import type { IGroup } from 'store/groups/types';

import { GroupImage } from './GroupImage';
import { GroupPrivacyStatus } from './GroupPrivacyStatus';
import { GroupMembersCount } from './GroupMembersCount';

import { st, classes } from './GroupCard.st.css';

type GroupCardProps = {
  group?: IGroup;
  onClick?(): void;
  loading?: boolean;
};

// TODO: use the component instead of SideBar/GroupCard after side bar will use v2 api
export const GroupCard: React.FC<GroupCardProps> = ({
  group,
  loading,
  onClick,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const skeletonClassName = loading ? skeleton : '';

  return (
    <div
      onClick={onClick}
      className={st(classes.root, { loading: !!loading, mobile: isMobile })}
    >
      <GroupImage coverImage={group?.coverImage} loading={loading} />
      <div className={classes.info}>
        <Text className={cls(classes.ellipsis, skeletonClassName)}>
          {group?.title}
        </Text>
        <Text
          className={cls(classes.ellipsis, skeletonClassName)}
          priority={TextPriority.secondary}
        >
          <GroupPrivacyStatus privacyStatus={group?.privacyStatus} />
          <span>&nbsp;&middot;&nbsp;</span>
          <GroupMembersCount
            count={group?.membersCount}
            label={group?.memberTitle}
          />
        </Text>
      </div>
      <BlackAndWhiteButton
        priority={ButtonPriority.primary}
        className={classes.selectButton}
      >
        {t('groups-web.select-group-modal.select')}
      </BlackAndWhiteButton>
    </div>
  );
};

export function GroupCardSkeleton() {
  return <GroupCard loading={true} />;
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import {
  ErrorMonitorBoundary,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';

import { IFeedItem } from 'api/feed/types';
import { selectFeed, selectFeedItems } from 'store/feed/selectors';

import { ErrorState } from 'Group/Widget/ErrorState';

import * as DATA_HOOKS from '../../dataHooks';
import { FeedItemSkeleton, FeedItem } from '../FeedItem';
import { EmptyResults } from '../EmptyResults';

import { components, computeItemKey } from './virtual';
import { FeedItemError } from './FeedItemError';

import { st, classes } from './FeedItemList.st.css';

interface FeedProps {
  showGroupName?: boolean;
  showSuggestedGroupLabel?: boolean;
  itemClassName?: string;
  className?: string;

  onCreatePost(): void;
  onFetch(cursor?: string): void;
}

export const FeedItemList: React.FC<FeedProps> = (props) => {
  const { onCreatePost, itemClassName, className } = props;
  const { isSSR, isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const state = useSelector(selectFeed);
  const items = useSelector(selectFeedItems);
  const useImprovedLayout = experiments.enabled(
    'specs.groups.GroupLayoutImprovements',
  );

  if (state.statuses.fetch.pending) {
    return (
      <div
        className={st(
          classes.root,
          { mobile: isMobile, improvedLayout: useImprovedLayout },
          className,
        )}
      >
        <FeedItemSkeleton className={itemClassName} />
        <FeedItemSkeleton className={itemClassName} />
        <FeedItemSkeleton className={itemClassName} />
      </div>
    );
  }

  if (state.statuses.fetch.error) {
    return <ErrorState className={itemClassName} onRetry={handleRetry} />;
  }

  if (!items.length) {
    return (
      <EmptyResults onCreatePost={onCreatePost} className={itemClassName} />
    );
  }

  return (
    <ErrorMonitorBoundary
      fallback={<ErrorState onRetry={handleRetry} className={itemClassName} />}
    >
      <Virtuoso
        data-hook={DATA_HOOKS.FEED}
        useWindowScroll
        data={items}
        endReached={fetchMore}
        computeItemKey={computeItemKey}
        initialItemCount={isSSR ? items.length : undefined}
        defaultItemHeight={400}
        totalCount={items.length}
        context={{ className: itemClassName, onRetry: handleRetry }}
        components={components}
        className={st(
          classes.root,
          { mobile: isMobile, improvedLayout: useImprovedLayout },
          className,
        )}
        itemContent={(_, item: IFeedItem) => (
          <ErrorMonitorBoundary
            fallback={
              <FeedItemError className={itemClassName} onRetry={handleRetry} />
            }
          >
            <FeedItem
              enablePreview
              item={item}
              showGroupName={props.showGroupName}
              showSuggestedGroupLabel={props.showSuggestedGroupLabel}
              className={itemClassName}
            />
          </ErrorMonitorBoundary>
        )}
      />
    </ErrorMonitorBoundary>
  );

  function handleRetry() {
    props.onFetch();
  }

  function fetchMore() {
    const { nextCursor, statuses } = state;

    if (
      nextCursor &&
      !statuses.fetchMore.pending &&
      !statuses.fetchMore.error
    ) {
      props.onFetch(nextCursor);
    }
  }
};

FeedItemList.displayName = 'FeedItemList';

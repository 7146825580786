import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';
import { TextButton } from 'wix-ui-tpa';

import { BIUserEntry } from 'common/bi-logger/types';
import { UISref } from 'common/router';

import { selectGroupIdBySlug } from 'store/groups/selectors';

import { st, classes } from './ActivityStatus.st.css';

type ClickableGroupNameProps = {
  slug: string;
  children?: React.ReactNode;
};

export const ClickableGroupName: React.FC<ClickableGroupNameProps> = (
  props,
) => {
  const { isMobile } = useEnvironment();

  const groupId = useSelector(selectGroupIdBySlug(props.slug));

  return (
    <UISref
      state="group"
      params={{ slug: props.slug }}
      bi={groupsSelectGroupFromList({
        origin: 'new_layout_groups_sidebar_post',
        groupId,
        userEntry: BIUserEntry.SITE,
      })}
    >
      <a target="_blank" rel="noreferrer">
        <TextButton className={st(classes.link, { mobile: isMobile })}>
          {props.children}
        </TextButton>
      </a>
    </UISref>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { DialogProps } from 'wix-ui-tpa';

import { ModalV2 } from 'common/components/Modal/ModalV2';
import { Search } from 'common/components/Search';
import { useController } from 'common/context/controller';

import { selectIsJoinedGroupsQueryLoading } from 'store/joined-groups/selectors';

import { SELECT_GROUP_MODAL_ROOT_DATA_HOOK } from './dataHooks';

import { JoinedGroups } from './JoinedGroups';

import { st, classes } from './SelectGroupModal.st.css';

interface SelectGroupsModalProps extends DialogProps {
  onSelectClick(groupId: string): void;
}

export const SelectGroupModal: React.FC<SelectGroupsModalProps> = ({
  onSelectClick,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = React.useState<string>('');

  const { groups$ } = useController();

  const loading = useSelector(selectIsJoinedGroupsQueryLoading);

  React.useEffect(() => {
    if (restProps.isOpen) {
      groups$.queryJoinedGroups(query);
    }
  }, [restProps.isOpen, query]);

  React.useEffect(() => {
    // clean query if close modal
    if (!restProps.isOpen) {
      setQuery('');
    }
  }, [restProps.isOpen]);

  return (
    <ModalV2
      {...restProps}
      className={st(classes.root)}
      data-hook={SELECT_GROUP_MODAL_ROOT_DATA_HOOK}
    >
      <ModalV2.Title>{t('groups-web.select-group-modal.title')}</ModalV2.Title>
      <ModalV2.Content>
        <Search
          withBorder={true}
          forceOpen={true}
          withCloseButton={true}
          placeholder={t('groups-web.select-group-modal.search')}
          onChange={handleSearchQueryChange}
          className={classes.search}
        />
        <div className={classes.groups} data-loading={loading}>
          <JoinedGroups query={query} onSelectClick={onSelectClick} />
        </div>
      </ModalV2.Content>
    </ModalV2>
  );

  function handleSearchQueryChange(groupName: string) {
    setQuery(groupName);
  }
};

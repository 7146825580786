import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import type { DraftContent } from 'ricos-editor';
import { EditorEventsProvider } from 'wix-rich-content-editor-common/dist/lib/EditorEventsContext';
import { IconButton, IconButtonSkins } from 'wix-ui-tpa';
import ShareIcon from 'wix-ui-icons-common/on-stage/Share';

import { IFeedItem } from 'api/feed/types';
import {
  selectGroupSlugById,
  selectIsGroupSecret,
} from 'store/groups/selectors';
import { selectFeedPermissions } from 'store/feed/selectors';

import { useHref } from 'common/router';
import { useController } from 'common/context/controller';
import { ShareModal } from 'common/components/ShareModal/ShareModal';
import { BlackAndWhiteThreeDots } from 'common/components/BlackAndWhiteThreeDots';

import settingsParams from 'Group/settingsParams';

import { useCurrentLayout } from 'Groups/Widget/hooks/useCurrentLayout';

import { PostEditorModal } from '../PostEditorModal';
import { DeletePostModal } from './DeletePostModal';

import { classes } from './FeedItem.st.css';
import { postActionClick } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from 'common/bi-logger/types';

enum EFeedAction {
  pin = 'pin',
  unpin = 'unpin',
  follow = 'follow',
  unfollow = 'unfollow',
  share = 'share',
  edit = 'edit',
  delete = 'delete',
}

interface IFeedAction {
  type: EFeedAction;
  content: string;
  shown: boolean;
  guard?: boolean;

  onClick(): void;
}

interface FeedItemActionsProps {
  item: IFeedItem;
}

export const FeedItemActions: React.FC<FeedItemActionsProps> = ({ item }) => {
  const { t } = useTranslation();
  const bi = useBi();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { isSidebarLayoutActive } = useCurrentLayout();
  const { feed$ } = useController();

  const groupId = item.applicationContext?.contextId as string;

  const slug = useSelector(selectGroupSlugById(groupId));
  const feedPermissions = useSelector(selectFeedPermissions);
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));

  const [shown, setShown] = useState(false);
  const [dialogs, setDialogs] = useState({
    edit: false,
    delete: false,
    share: false,
  });

  const postUrl = useHref(
    'group.discussion.post',
    {
      slug,
      feedItemId: item.feedItemId,
    },
    { absolute: true },
  );

  const showShareButton =
    settings.get(settingsParams.showShareButton) &&
    !experiments.enabled('specs.groups.NewReactions') &&
    !isGroupSecret;

  const actions = React.useMemo<IFeedAction[]>(
    () => [
      {
        type: EFeedAction.pin,
        content: t('groups-web.discussion.feed.post-actions.pin-post'),
        shown: !item.pin,
        guard: feedPermissions?.canPinPosts,
        onClick: handlePin,
      },
      {
        type: EFeedAction.unpin,
        content: t('groups-web.discussion.feed.post-actions.unpin-post'),
        shown: !!item.pin,
        guard: feedPermissions?.canPinPosts,
        onClick: handleUnpin,
      },
      {
        type: EFeedAction.follow,
        content: t('groups-web.discussion.feed.post-actions.follow'),
        shown: !item.requesterContext?.subscribed,
        guard: true,
        onClick: handleFollow,
      },
      {
        type: EFeedAction.unfollow,
        content: t('groups-web.discussion.feed.post-actions.following'),
        shown: !!item.requesterContext?.subscribed,
        guard: true,
        onClick: handleUnfollow,
      },
      {
        type: EFeedAction.share,
        content: t('groups-web.discussion.feed.post-actions.share'),
        shown: true,
        guard: item.permissions?.canShare,
        onClick: openDialogHandler('share'),
      },
      {
        type: EFeedAction.edit,
        content: t('groups-web.discussion.feed.post-actions.edit'),
        shown: true,
        guard: item.permissions?.canUpdate,
        onClick: openDialogHandler('edit'),
      },
      {
        type: EFeedAction.delete,
        content: t('groups-web.discussion.feed.post-actions.delete'),
        shown: true,
        guard: item.permissions?.canDelete,
        onClick: openDialogHandler('delete'),
      },
    ],
    [item.pin, item.requesterContext],
  );

  const allowedActions = actions
    .filter((action) => action.shown)
    .filter((action) => action.guard);

  if (!allowedActions.length) {
    return null;
  }

  return (
    <>
      {showShareButton && (
        <IconButton
          aria-label="Share"
          data-hook="share-button"
          skin={IconButtonSkins.Full}
          className={classes.shareButton}
          onClick={openDialogHandler('share')}
          icon={<ShareIcon width="24px" height="24px" />}
        />
      )}
      <div>
        <BlackAndWhiteThreeDots
          isOpen={shown}
          onOpen={handleOpen}
          onClose={handleClose}
          items={allowedActions}
          iconClassName={classes.threeDotsIcon}
        />
        <ShareModal
          isOpen={dialogs.share}
          shareUrl={postUrl}
          onClose={closeDialogHandler('share')}
          title={t('groups-web.discussion.feed.post-actions.share.dialog')}
        />
        <DeletePostModal
          isOpen={dialogs.delete}
          feedItemId={item.feedItemId as string}
          onClose={closeDialogHandler('delete')}
          onSubmit={handleDelete}
        />

        <EditorEventsProvider>
          <PostEditorModal
            groupId={groupId}
            feedItem={item}
            isOpen={dialogs.edit}
            onSubmit={handleEdit}
            onClose={closeDialogHandler('edit')}
          />
        </EditorEventsProvider>
      </div>
    </>
  );

  function handlePin() {
    repostPostActionBi('pin');

    feed$.pin(groupId, item.feedItemId as string);
    handleClose();
  }

  function handleUnpin() {
    repostPostActionBi('unpin');

    feed$.unpin(groupId, item.feedItemId as string);
    handleClose();
  }

  function handleFollow() {
    repostPostActionBi('follow');

    feed$.subscribe(groupId, item.feedItemId as string);
    handleClose();
  }

  function handleUnfollow() {
    repostPostActionBi('unfollow');

    feed$.unsubscribe(groupId, item.feedItemId as string);
    handleClose();
  }

  function handleDelete() {
    repostPostActionBi('delete');

    feed$.remove(groupId, item.feedItemId as string);
    handleClose();
  }

  function handleEdit(content: DraftContent, topicIds: string[]) {
    repostPostActionBi('edit');

    feed$.update(
      groupId,
      item.feedItemId as string,
      JSON.stringify(content),
      topicIds,
    );
  }

  function openDialogHandler(dialog: keyof typeof dialogs) {
    return () => {
      handleClose();
      repostPostActionBi(dialog);
      setDialogs((dialogs) => ({ ...dialogs, [dialog]: true }));
    };
  }

  function closeDialogHandler(dialog: keyof typeof dialogs) {
    return () => setDialogs((dialogs) => ({ ...dialogs, [dialog]: false }));
  }

  function handleOpen() {
    setShown(true);
  }

  function handleClose() {
    setShown(false);
  }

  function repostPostActionBi(action: string) {
    bi.report(
      postActionClick({
        action,
        group_id: groupId,
        post_id: item.feedItemId,
        origin: isSidebarLayoutActive
          ? 'three_dots_sidebar'
          : 'three_dots_menu',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }
};

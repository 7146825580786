import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { Text, TextButton, TextButtonPriority } from 'wix-ui-tpa';

import settingsParams from 'Groups/settingsParams';
import { GROUP_LIST_LINK_DATA_HOOK } from './dataHooks';

import { st, classes } from './Header.st.css';

type HeaderProps = {
  onClick(): void;
};

export const Header: React.FC<HeaderProps> = ({ onClick }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const settings = useSettings();

  const customFeedTitle = settings.get(settingsParams.feedTitle);
  const feedTitle = customFeedTitle
    ? customFeedTitle
    : t('groups-web.settings.TextTab.feedTitle.default');

  return (
    <div
      className={st(classes.root, {
        alignment: settings.get(settingsParams.headerAlignment),
        mobile: isMobile,
      })}
    >
      <Text className={classes.title}>{feedTitle}</Text>
      {isMobile ? (
        <TextButton
          data-hook={GROUP_LIST_LINK_DATA_HOOK}
          priority={TextButtonPriority.primary}
          onClick={onClick}
        >
          {t('groups-web.group-list.label')}
        </TextButton>
      ) : null}
    </div>
  );
};

import React from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

import { MembersLabelWithCountDict } from './MembersLabel';
import { getKey } from 'common/components/PluralText';

interface MembersLabelWithCountProps {
  groupMemberLabel?: string;
  count: number;
}

export const MembersLabelWithCount: React.FC<MembersLabelWithCountProps> = (
  props,
) => {
  const { groupMemberLabel, count } = props;
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const translationKey = groupMemberLabel
    ? MembersLabelWithCountDict[
        groupMemberLabel as keyof typeof MembersLabelWithCountDict
      ]
    : MembersLabelWithCountDict.Members;

  const label = translationKey
    ? t(getKey(experiments, translationKey, count), { count })
    : `${count} ${groupMemberLabel}`;
  return <span data-hook="members-count">{label}</span>;
};

MembersLabelWithCount.displayName = 'MembersLabelWithCount';

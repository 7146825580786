import React from 'react';
import TagIcon from 'wix-ui-icons-common/Tag';

import { IFeedItem } from 'api/feed';

import { Topic } from 'Group/Widget/DiscussionPage/GroupTopics/Topic';

import { classes } from './Topics.st.css';

export interface TopicsProps {
  item: IFeedItem;
}

export const Topics: React.FC<TopicsProps> = (props) => {
  const { item } = props;

  if (!item.entity?.topics?.length) {
    return null;
  }

  return (
    <ul className={classes.root}>
      <li className={classes.item}>
        <TagIcon />
      </li>
      {item.entity.topics.map((topic) => (
        <li key={topic.id} className={classes.item}>
          <Topic topic={topic} />
        </li>
      ))}
    </ul>
  );
};

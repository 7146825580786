import React from 'react';

import { PluralText } from 'common/components/PluralText';
import { MembersLabelWithCountDict } from 'common/components/MembersLabel/MembersLabel';

interface GroupMembersCountProps {
  count?: number | null;
  label?: string | null;
}

export const GroupMembersCount: React.FC<GroupMembersCountProps> = ({
  count,
  label,
}) => {
  const translationKey = label
    ? MembersLabelWithCountDict[label as keyof typeof MembersLabelWithCountDict]
    : MembersLabelWithCountDict.Members;

  const membersLabel = translationKey ? (
    <PluralText translationKey={translationKey} count={count || 0} />
  ) : (
    `${count} ${label}`
  );

  return <span data-hook="members-count">{membersLabel}</span>;
};

import React from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  selectIsJoinedGroupsQueryLoading,
  selectJoinedGroups,
  selectJoinedGroupsTotal,
} from 'store/joined-groups/selectors';

import { useController } from 'common/context/controller';
import { NoGroups } from 'common/components/GroupList/NoGroups';

import { GroupCard, GroupCardSkeleton } from '../../GroupCard';

type JoinedGroupsProps = {
  query: string;
  onSelectClick(groupId: string): void;
};

export const JoinedGroups: React.FC<JoinedGroupsProps> = ({
  query,
  onSelectClick,
}) => {
  const { t } = useTranslation();
  const { groups$ } = useController();

  const groups = useSelector(selectJoinedGroups);
  const loading = useSelector(selectIsJoinedGroupsQueryLoading);
  const groupsTotal = useSelector(selectJoinedGroupsTotal);

  if (loading) {
    return (
      <>
        <GroupCardSkeleton />
        <GroupCardSkeleton />
        <GroupCardSkeleton />
        <GroupCardSkeleton />
        <GroupCardSkeleton />
      </>
    );
  }

  if (!groups.length) {
    return (
      <NoGroups
        emptyStateHeader={t('groups-web.search.no-results.title')}
        emptyStateText={t('groups-web.search.no-results.text')}
      />
    );
  }

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={groupsTotal > groups.length}
      loadMore={handleLoadMore}
      threshold={200}
      loader={
        <>
          <GroupCardSkeleton />
          <GroupCardSkeleton />
          <GroupCardSkeleton />
        </>
      }
    >
      {groups.map((group) => {
        return (
          <GroupCard group={group} onClick={() => onSelectClick(group.id!)} />
        );
      })}
    </InfiniteScroll>
  );

  function handleLoadMore() {
    groups$.queryJoinedGroups(query, groups.length);
  }
};

export const GROUP_WRAPPER = 'Group-wrapper';
export const DATA_HOOKS = {
  root: 'group-page',
};
export const NEW_POST_MODAL = 'new-post-modal';
export const JOIN_GROUP_DIALOG = 'join-group-dialog';
export const MEDIA_GALLERY = 'media-gallery';
export const MEMBERS_TAB = 'members-tab';
export const PENDING_MEMBERS_EXPAND = 'pending-members-expand';
export const GROUP_HEADER = 'group-header';

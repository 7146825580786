import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { GroupResponse as ApiTypesV1GroupResponse } from '@wix/ambassador-social-groups-v1-group/types';

import { Header } from 'common/components/Text/Header';

import settingsParams from '../../../../../settingsParams';
import { GroupCard } from '../GroupCard/GroupCard';

import { st, classes } from './Groups.st.css';

type GroupsProps = {
  title: string;
  groups: ApiTypesV1GroupResponse[];
  goToGroup?(groupId: string): void;
};

export const Groups: React.FC<GroupsProps> = (props) => {
  const { isMobile } = useEnvironment();

  const settings = useSettings();
  const shouldShowImage = settings.get(settingsParams.showImage);

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <Header
        textAlign={settings.get(settingsParams.headerAlignment)}
        className={classes.header}
      >
        {props.title}
      </Header>
      <div>
        {props.groups.map((group) => (
          <GroupCard
            group={group}
            goToGroup={props.goToGroup}
            withImage={shouldShowImage}
          />
        ))}
      </div>
    </div>
  );
};

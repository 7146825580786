import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import cls from 'classnames';

import {
  Reactions,
  ReactionType,
  TextButton,
  TextButtonPriority,
  ShareButton,
} from 'wix-ui-tpa';

import { Comment } from '@wix/wix-ui-icons-common/on-stage';

import { IFeedItem, ReactionMode } from 'api/feed/types';
import {
  selectGroupSlugById,
  selectIsGroupSecret,
} from 'store/groups/selectors';
import { groupSettingsParams } from 'settings/group';

import { useHref } from 'common/router';
import { useController } from 'common/context/controller';
import { ShareModal } from 'common/components/ShareModal';

import { ReactedMembers } from '../ReactionsAndCommentsCounterRow/ReactedMembers';
import { TotalComments } from '../ReactionsAndCommentsCounterRow/TotalComments';
import {
  selectHasReactions,
  selectReactedUsers,
  selectReactionsMap,
} from '../ReactionsAndCommentsCounterRow/selectors';

import {
  fromReactionType,
  toReactionType,
  getReactionLabel,
  getReactionIcon,
} from './helpers';

import { selectUserReaction } from './selectors';
import { ADDITIONAL_REACTIONS } from './constants';

import { st, classes } from './SocialBar.st.css';

interface ISocialBarProps {
  item: IFeedItem;

  onCommentsToggle(): void;
  onCommentClick(): void;
}

export function SocialBar(props: ISocialBarProps) {
  const { item } = props;

  const userReaction = useSelector(
    selectUserReaction(item.feedItemId as string),
  );

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const canReact = item.permissions?.canReact;

  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { feed$, application$ } = useController();

  const slug = useSelector(selectGroupSlugById(groupId));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));
  const reactedUsers = useSelector(selectReactedUsers(item.feedItemId!));
  const reactionsMap = useSelector(selectReactionsMap(item.feedItemId!));
  const hasReactions = useSelector(selectHasReactions(item.feedItemId!));

  const [shareDialogOpened, setShareDialogOpened] = useState(false);
  const [selectedId, setSelectedId] = useState(
    toReactionType(userReaction?.code as string),
  );

  const canShare =
    settings.get(groupSettingsParams.showShareButton) && !isGroupSecret;

  const postUrl = useHref(
    'group.discussion.post',
    {
      slug,
      feedItemId: item.feedItemId,
    },
    { absolute: true },
  );

  useEffect(() => {
    setSelectedId(toReactionType(userReaction?.code as string));
  }, [userReaction?.code]);

  return (
    <>
      {hasReactions && (
        <div className={cls(classes.meta, { [classes.isMobile]: isMobile })}>
          <ReactedMembers
            legacy={false}
            users={reactedUsers}
            reactionsMap={reactionsMap}
            reactions={item.reactions}
          />
          <TotalComments item={item} onClick={props.onCommentsToggle} />
        </div>
      )}
      <div className={classes.root}>
        <div className={classes.item}>
          <Reactions
            selectedId={selectedId}
            popoverTriggerAriaLabel="Reactions"
            onReactionSelect={handleReactionSelect}
            additionalReactions={ADDITIONAL_REACTIONS}
            reactionLabel={(id) => t(getReactionLabel(id))}
            reactionIcon={(id) => getReactionIcon(id) as React.ReactElement}
            defaultReaction="thumbsup"
            theme="light"
          />
        </div>
        <div className={classes.item}>
          <TextButton
            prefixIcon={<Comment />}
            priority={TextButtonPriority.secondary}
            onClick={props.onCommentClick}
          >
            {t('groups-web.feed.feed-item.social-bar.comment')}
          </TextButton>
        </div>
        {canShare && (
          <div className={classes.item}>
            <ShareButton
              withIcon
              onClick={openShareDialog}
              shareData={{ url: postUrl }}
              text={t('groups-web.feed.feed-item.social-bar.share')}
            />
          </div>
        )}
      </div>

      <ShareModal
        isOpen={shareDialogOpened}
        shareUrl={postUrl}
        onClose={closeShareDialog}
        title={t('groups-web.discussion.feed.post-actions.share.dialog')}
      />
    </>
  );

  function handleReactionSelect(id: ReactionType) {
    if (!canReact) {
      application$.showDialog({
        groupId,
        dialog: 'joinGroup',
      });

      return;
    }

    if (selectedId === id || !id) {
      unreact(id || (selectedId as ReactionType));
    } else {
      react(id);
    }
  }

  function openShareDialog(native?: Promise<void>) {
    if (!native) {
      setShareDialogOpened(true);
    }
  }

  function closeShareDialog() {
    setShareDialogOpened(false);
  }

  function react(id: ReactionType) {
    const code = fromReactionType(id);

    setSelectedId(id);
    feed$.react(groupId, feedItemId, code, ReactionMode.REPLACE);
  }

  function unreact(id: ReactionType) {
    const code = fromReactionType(id);

    setSelectedId(undefined);
    feed$.unreact(groupId, feedItemId, code);
  }
}

import type { IRootState } from '../types';

import { selectors } from './adapter';

export const selectJoinedGroups = (state: IRootState) =>
  selectors.selectAll(state);

export const selectJoinedGroupsTotal = (state: IRootState) =>
  state.joinedGroups.metadata.total || 0;

export const selectIsJoinedGroupsQueryLoading = (state: IRootState) =>
  state.joinedGroups.loading;

import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { PluralText } from 'common/components/PluralText';
import { IFeedItem } from 'api/feed';

import { classes } from './TotalComments.st.css';

interface TotalCommentsProps {
  onClick(): void;
  item: IFeedItem;
}

export const TotalComments: React.FC<TotalCommentsProps> = ({
  onClick,
  item,
}) => {
  return (
    <span onClick={onClick}>
      <Text typography={TextTypography.listText} className={classes.root}>
        <PluralText
          translationKey="groups-web.discussion.feed.total-comments"
          count={item.comments?.total || 0}
        />
      </Text>
    </span>
  );
};

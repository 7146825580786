import { useMemo } from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

import { getKey } from 'common/components/PluralText';

interface IUserSearchPlaceholder {
  isSecret: boolean;
  count?: number;
}

export function useSearchPlaceHolder(props: IUserSearchPlaceholder) {
  const { count, isSecret } = props;

  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const placeholder = useMemo(() => {
    let key = '';
    if (isSecret) {
      key = getKey(
        experiments,
        'groups-web.secret-group.add.members.search.placeholder',
        count,
      );
    } else {
      key = count
        ? getKey(
            experiments,
            'groups-web.add.members.search.placeholder',
            count,
          )
        : 'groups-web.add.members.search.no-site-members.placeholder';
    }
    return t(key, { count });
  }, [count, isSecret]);

  return placeholder;
}

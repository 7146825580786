import React, { useMemo } from 'react';

import { createHttpClient, useEnvironment } from '@wix/yoshi-flow-editor';
import { SortOrder } from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';
import { queryGroupMembers } from 'api/members';
import { useRouter } from 'common/router';
import { useSelector } from 'react-redux';
import { selectUserToken } from 'store/application/selectors';
import { selectGroupSlugById } from 'store/groups/selectors';

export const EVERYONE_MENTION_GUID = 'everyone';
export const EVERYONE_MENTION_DISPLAY_NAME = 'Everyone';

type Mention = {
  id: string;
  name: string;
  avatar?: string;
  type?: 'GROUP';
};

export function useMentions(groupId: string, isComments?: boolean) {
  const { isSSR } = useEnvironment();
  const router = useRouter();

  const instance = useSelector(selectUserToken);
  const slug = useSelector(selectGroupSlugById(groupId));

  const httpClient = useMemo(
    () =>
      createHttpClient({
        isSSR,
        getAppToken() {
          return instance;
        },
      }),
    [instance, isSSR],
  );

  return {
    getMentions,
    onMentionClick,
  };

  function onMentionClick(mention: Mention) {
    const isEveryone =
      mention.id === EVERYONE_MENTION_GUID || mention.type === 'GROUP';

    if (isEveryone) {
      router.go('group.members', { slug });
    } else {
      router.go('members.profile', { memberId: mention.id });
    }
  }

  async function getMentions(query: string) {
    const { data } = await httpClient.request(
      queryGroupMembers(groupId, {
        paging: {
          limit: 20,
        },
        sort: [
          {
            fieldName: 'profile.nickname',
            order: SortOrder.ASC,
          },
        ],
        filter: {
          'profile.nickname': {
            $contains: query,
          },
        },
      }),
    );

    const members: Mention[] = data.members.map((member) => ({
      id: member.memberId as string,
      name: member.name,
      avatar: member.imageUrl,
    }));

    if (
      EVERYONE_MENTION_DISPLAY_NAME.toLowerCase().includes(query.toLowerCase())
    ) {
      members.unshift({
        type: 'GROUP',
        id: isComments ? groupId : EVERYONE_MENTION_GUID,
        name: EVERYONE_MENTION_DISPLAY_NAME,
      });
    }

    return members;
  }
}

import React from 'react';

import { Components } from 'react-virtuoso';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { classes } from '../FeedItemList.st.css';

export const Item: Components['Item'] = (props) => {
  return (
    <CSSTransition
      {...props}
      mountOnEnter
      unmountOnExit
      classNames={classes as CSSTransitionClassNames}
      timeout={{ appear: 700, enter: 300, exit: 700 }}
    >
      <div>{props.children}</div>
    </CSSTransition>
  );
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import {
  TextButtonPriority,
  TextField,
  Text,
  TextTypography,
  Tag,
  TagSize,
  Popover,
  PopoverTriggerAction,
  TagSkin,
} from 'wix-ui-tpa';
import TagIcon from 'wix-ui-icons-common/Tag';
import {
  groupFeedTopicsAddTopicClickIntent,
  groupFeedTopicsTopicActions,
} from '@wix/bi-logger-groups/v2';

import {
  selectLastCreatedTopic,
  filterTopics,
  selectTopicsStatus,
} from 'store/topics/selectors';
import { selectFeedPermissionsByGroup } from 'store/groups/selectors';
import type { ITopic } from 'api/topics/types';

import { useDidUpdate } from 'common/hooks';

import { useController } from 'common/context/controller';
import { BIUserEntry } from 'common/bi-logger/types';
import { BlackAndWhiteTextButton } from 'common/components/BlackAndWhiteTextButton';
import { skeleton } from 'common/components/Skeleton';
import { AddIcon } from 'Group/Widget/icons/AddIcon';

import { st, classes } from './TopicSelect.st.css';

export interface ITopicSelectProps {
  groupId: string;
  isEditable?: boolean;
  feedItemId?: string;
  selected: ITopic[];

  onChange(topics: ITopic[]): void;
}

export function TopicSelect(props: ITopicSelectProps) {
  const { feedItemId, onChange, groupId, isEditable, selected } = props;

  const { t } = useTranslation();
  const bi = useBi();

  const { topics$ } = useController();

  const [search, setSearch] = React.useState<string>();
  const [isOpen, setIsOpen] = React.useState(false);

  const topics = useSelector(filterTopics(search || ''));
  const statuses = useSelector(selectTopicsStatus);
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));
  const lastCreated = useSelector(selectLastCreatedTopic);

  const canCreate = !!permissions?.canCreateTopic;
  const isCreating = statuses.create.pending;
  const isLoading = statuses.fetch.pending;

  useEffect(() => {
    if (isOpen && groupId) {
      topics$.fetch(groupId);
    }
  }, [groupId, isOpen]);

  useDidUpdate(() => {
    if (lastCreated) {
      selectTopic(lastCreated);
    }
  }, [lastCreated?.id]);

  return (
    <Popover
      wiredToSiteColors={false}
      placement="top-start"
      triggerAction={PopoverTriggerAction.click}
      shown={isOpen}
      onClickOutside={() => setIsOpen(false)}
      disableClickOutsideWhenClosed
    >
      <Popover.Element>
        <div className={st(classes.root)}>
          {selected.length ? (
            <ul className={classes.selectedList}>
              <li className={classes.selectedListItem}>
                <TagIcon />
              </li>
              {selected.slice(0, 2).map((topic) => (
                <li key={topic.id} className={classes.selectedListItem}>
                  <Tag
                    className={classes.tag}
                    skin={TagSkin.light}
                    size={TagSize.small}
                    onClick={!isEditable ? undefined : () => setIsOpen(true)}
                  >
                    {topic.displayName}
                  </Tag>
                </li>
              ))}
              {selected.length > 2 && (
                <li className={classes.selectedListItem}>
                  <Text typography={TextTypography.runningText}>
                    {`+${selected.length - 2}`}
                  </Text>
                </li>
              )}
            </ul>
          ) : (
            <BlackAndWhiteTextButton
              onClick={() => setIsOpen(true)}
              priority={TextButtonPriority.secondary}
              prefixIcon={<AddIcon width="24px" height="24px" />}
            >
              {t('groups-web.discussion.topics.modal.open-button')}
            </BlackAndWhiteTextButton>
          )}
        </div>
      </Popover.Element>
      <Popover.Content>
        <div className={st(classes.content)}>
          <TextField
            className={classes.input}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t(
              'groups-web.discussion.topics.modal.search.placeholder',
            )}
            maxLength={40}
          />
          <Text
            tagName="p"
            className={st(classes.label)}
            typography={TextTypography.runningText}
          >
            {t(getStatusLabel())}
          </Text>
          {isLoading ? (
            <ul className={st(classes.list)}>
              <li className={st(classes.item)}>
                <Tag
                  className={cls(classes.tag, skeleton)}
                  size={TagSize.small}
                >
                  Lorem, ipsum.
                </Tag>
              </li>
              <li className={st(classes.item)}>
                <Tag
                  className={cls(classes.tag, skeleton)}
                  size={TagSize.small}
                >
                  Lorem
                </Tag>
              </li>
              <li className={st(classes.item)}>
                <Tag
                  className={cls(classes.tag, skeleton)}
                  size={TagSize.small}
                >
                  Lorem ipsum dolor.
                </Tag>
              </li>
            </ul>
          ) : (
            <ul className={st(classes.list)}>
              {topics.map((topic) => {
                const isSelected = props.selected.some(
                  ({ id }) => id === topic.id,
                );

                return (
                  <li className={st(classes.item)}>
                    <Tag
                      key={topic.id}
                      isRemovable={isSelected}
                      size={TagSize.small}
                      skin={TagSkin.light}
                      onClick={() => selectTopic(topic)}
                      onRemove={() => removeTopic(topic)}
                      className={cls(classes.tag, {
                        [classes.selected]: isSelected,
                      })}
                    >
                      {`${topic.displayName} (${topic.count})`}
                    </Tag>
                  </li>
                );
              })}
              {canCreate && search && (
                <li className={cls(classes.item, classes.create)}>
                  <BlackAndWhiteTextButton
                    disabled={isCreating}
                    priority={TextButtonPriority.secondary}
                    onClick={() => createTopic(search)}
                    prefixIcon={<AddIcon width="24px" height="24px" />}
                  >
                    {t('groups-web.discussion.topics.modal.create-button')}
                  </BlackAndWhiteTextButton>
                  &nbsp;&nbsp;
                  <Tag
                    size={TagSize.small}
                    skin={TagSkin.light}
                    className={classes.tag}
                  >
                    {search}
                  </Tag>
                </li>
              )}
            </ul>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  function getStatusLabel() {
    if (!topics.length && !!search) {
      return 'groups-web.discussion.topics.modal.status-label.filters-empty';
    }

    if (!topics.length) {
      return canCreate
        ? `groups-web.discussion.topics.modal.status-label.empty.admin`
        : 'groups-web.discussion.topics.modal.status-label.empty.member';
    }

    return canCreate
      ? 'groups-web.discussion.topics.modal.status-label.admin'
      : 'groups-web.discussion.topics.modal.status-label.member';
  }

  async function createTopic(topicName: string) {
    topics$.create(groupId, topicName);

    bi.report(
      groupFeedTopicsAddTopicClickIntent({
        groupId,
        origin: 'topics_list_plus_btn_in_post',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }

  function removeTopic(topic: ITopic) {
    onChange(props.selected.filter(({ id }) => id !== topic.id));

    bi.report(
      groupFeedTopicsTopicActions({
        groupId,
        action: 'delete',
        origin: feedItemId ? 'post_creation' : 'post_to_the_topic',
        topicName: topic.displayName,
        topicId: topic.id,
        userEntry: BIUserEntry.SITE,
      }),
    );
  }

  function selectTopic(topic: ITopic) {
    handleSelect(topic);

    if (feedItemId) {
      bi.report(
        groupFeedTopicsTopicActions({
          groupId,
          action: 'edit',
          origin: 'post_to_the_topic',
          topicName: topic.displayName,
          topicId: topic.id,
          userEntry: BIUserEntry.SITE,
        }),
      );
    } else {
      bi.report(
        groupFeedTopicsAddTopicClickIntent({
          groupId,
          origin: 'topics_list_in_post',
          userEntry: BIUserEntry.SITE,
        }),
      );
    }
  }

  function handleSelect(topic: ITopic) {
    onChange([...props.selected, topic]);
    setSearch(undefined);
  }
}
